import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from "gatsby-image"
import SEO from '../components/seo'
import Layout from '../components/Layout'
//import { rhythm } from '../utils/typography'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { SlugLink } from '../components/utils'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class BlogIndex extends React.Component {
    renderTags(tags) {
        tags.map((item) => {
            //console.log(item)
            return <Button variant="outline-secondary">{item}</Button>
        })
    }
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const siteDescription = data.site.siteMetadata.description
        const domainName = data.site.siteMetadata.domainName
        const siteUrl = data.site.siteMetadata.siteUrl
    //    const siteUrl = 'http://localhost:8000'
        const posts = data.allMarkdownRemark.edges
        const { currentPage, numPages } = this.props.pageContext
        let curr = 1;
        if (currentPage === undefined) { curr = 1 }
        else { curr = currentPage }
        const isFirst = curr === 1
        const isLast = curr === numPages
        const prevPage = curr - 1 === 1 ? '/' : (curr - 1).toString()
        const nextPage = (curr + 1).toString()
        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    title={`${siteTitle} ${currentPage === 1 ? '' : `- Trang ${currentPage}`}`}
                    description={`${siteDescription} ${currentPage === 1 ? '' : `- Trang ${currentPage}`}`}
                    canonical={`https://${domainName}${currentPage === 1 ? '' : `/page/${currentPage}`}`}
                />
                <div className="row">
                    {posts.map(({ node }) => {
                        let featuredImgFluid = node.frontmatter.cover.childImageSharp.fluid.src
                        const title = node.frontmatter.title || node.fields.slug
                        return (

                            <div className="col-md-6">
                                <article class="archive-post mts-post" key={node.fields.slug}>
                                    <a href={siteUrl + node.fields.slug} title={title} class="post-image post-image-full">
                                        <div class="featured-thumbnail">
                                            <LazyLoadImage
                                                className="wp-post-image"
                                                alt={title}
                                                title={title}
                                                src={featuredImgFluid}
                                                effect="blur"
                                            />
                                        </div>
                                    </a>
                                    <div class="post-content">
                                        <div class="post-meta">
                                            <span class="thecategory">
                                                {node.frontmatter.category !== null ? <a style={{ color: 'rgba(0, 0, 0, .44)', }} href={siteUrl + `/category/${SlugLink(node.frontmatter.category)}`}>{node.frontmatter.category}</a> : null}
                                            </span>
                                            <span class="thetime updated">{node.frontmatter.date}</span>
                                        </div>
                                        <header>
                                            <h2 class="post-title">
                                                <a href={siteUrl + node.fields.slug} title={title}>{title}</a></h2>
                                        </header>
                                        <div class="post-excerpt">
                                            <p className="tb-post-card__subhead" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                                            <a href={siteUrl + node.fields.slug} title={title} class="read-more-link">Read More</a>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        )
                    })}
                </div>
                <div className="row pagination">
                    <ul className="col d-flex justify-content-center">
                        {!isFirst && (
                            <li className="tb-pagination__link">
                                <Link to={curr <= 2 ? `/` : `/page/${prevPage}`} rel="prev">
                                    ←
                </Link>
                            </li>
                        )}
                        {Array.from({ length: numPages }, (_, i) => {
                            if (i < curr + 3 && i > curr - 3) {
                                return (
                                    <li
                                        key={`pagination-number${i + 1}`}
                                        className="tb-pagination__link"
                                    >
                                        <Link
                                            to={`/${i === 0 ? '' : 'page/' + (i + 1)}`}
                                            className={i + 1 === curr ? "current" : "page-s"}
                                        >
                                            {i + 1}
                                        </Link>
                                    </li>
                                )
                            }
                        })}
                        {!isLast && (
                            <li className="tb-pagination__link">
                                <Link to={`/page/${nextPage}`} rel="next">
                                    →
                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </Layout >
        )
    }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        domainName
        description
        siteUrl
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            tags
            category
            cover {
                childImageSharp {
                    fluid {
                        src
                      }
                }
              }
          }
          html
          timeToRead
        }
      }
    }
  }
`
